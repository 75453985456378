import { Role } from './role';

export class UserType {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  Role: Role;
  UserType: string;
  Permissions: {
    Resolution: boolean,
    Sales: boolean,
    CRM:boolean,
    FAQ:boolean,
    ResolutionFAQ:boolean,
    SalesFAQ:boolean,
    UserDefinition:boolean,
    DataUpload:boolean,
    DataDefinition:boolean
  };
}
