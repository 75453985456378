export enum Role {
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin',
    Agent = 'Agent',
}

export interface roleField {
    value: string;
    permissions: string;
    role_status: string;
    edit: string;
}