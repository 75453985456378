import { environment } from "environments/environment";
export let ServicesListobject;
export let GOOGLE_MAPS_API_KEY = "";
export let otherServicesID;
export let currentCallDropdown = [
  { key: "INBOUND_CUSTOMER", value: "Inbound Customer" },
  { key: "INBOUND_CLIENT", value: "Inbound Client" },
  { key: "INBOUND_VENDOR", value: "Inbound Vendor" },
  { key: "OUTBOUND_CUSTOMER", value: "Outbound Customer" },
  { key: "OUTBOUND_CLIENT", value: "Outbound Client " },
  { key: "OUTBOUND_VENDOR", value: "Outbound Vendor" },
  { key: "OUTBOUND_MANAGER", value: "Outbound Manager" },
  { key: "ESCALATION", value: "Escalation" },
  { key: "QUERY", value: "Query" },
  { key: "COMPLAINT", value: "Complaint" },
];
export let callSubTypeDropdown = [
  { key: "BOOKING_REFUND", value: "Booking refund" },
  { key: "BOOKING_CANCELLATION", value: "Booking cancellation" },
  { key: "BOOKING_RESCHEDULE", value: "Booking reschedule" },
  { key: "ACCOUNT_RELATED", value: "Account related" },
  { key: "ORDER_DELIVERY", value: "Order delivery" },
  { key: "ORDER_REFUND", value: "Order Refund" },
  { key: "ORDER_CALCELLATION", value: "Order cancellation" },
  { key: "GENERAL_BOOKING", value: "General Booking" },
];
export let ticketFinalStatusDropDown = [
  { key: "PENDING_FULFILLMENT/QUEUE", value: "Pending fulfillment/Queue" },
  { key: "QUEUE_GOLF_QUEUE", value: "Queue-Golf queue" },
  {
    key: "QUEUE_CONCIERGE_QUEUE/ANOTHER_QUEUE",
    value: "Queue- Concierge queue/Another queue",
  },
  {
    key: "QUEUE_AIRPORT_SERVICES_QUEUE",
    value: "Queue-Airport Services queue",
  },
  {
    key: "FULFILLED_FIRST_CHOICE_BOOKED",
    value: "Fulfilled- First Choice Booked",
  },
  {
    key: "FULFILLED_ALTERNATIVE_BOOKED",
    value: "Fulfilled- Alternative Booked",
  },
  {
    key: "FULFILLED_INFORMATION_SHARED",
    value: "Fulfilled- Information Shared",
  },
  { key: "DULFILLED_CANCELLED", value: "Fulfilled- Cancelled" },
  { key: "FULFILLED_TRANSFERRED", value: "Fulfilled- Transferred" },
  { key: "FULFILLED_INELIGIBLE", value: "Fulfilled- Ineligible" },
  {
    key: "UNFULFILLED_UNABLE_TO_SOURCE",
    value: "Unfulfilled- Unbale to Source",
  },
  {
    key: "UNFULFILLED_SOLD_OUT/FULLY_BOOKED",
    value: "Unfulfilled - Sold Out/Fully Booked",
  },
  {
    key: "UNFULFILLED_DECLINED_(OUT_OF_SCOPE)",
    value: "Unfulfilled - Declined (Out of Scope)",
  },
];
export let ticketSubStatusDropDown = [
  { key: "AWAIT_CONCIERGE", value: "Await Concierge" },
  { key: "AWAIT_VENDOR", value: "Await Vendor" },
  { key: "AWAIT_CLIENT", value: "Await Client" },
  { key: "AWAIT_MANAGER", value: "Await Manager" },
  { key: "AWAIT_QUALITY_TEAM", value: "Await Quality Team" },
  { key: "REQUEST_CLOSED", value: "Request closed" },
];
export let countryTypeDropdown = [
  { key: "INDIA", value: "India" },
  { key: "OUT_OF_INDIA", value: "Out Of India" },
];
export let itemsOfWalletCheckbox = [
  { key: "AADHAR_CARD", value: "Aadhar Card" },
  { key: "DEBIT_/_CREDIT_CARD", value: "Debit/Credit Card" },
  { key: "DRIVING_LICENSE", value: "Driving License" },
  { key: "PAN_CARD", value: "PAN Card" },
];
export let cardPatternValidation =
  "^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|37[0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$";
export let aadharCardValidation = "^[2-9][0-9]{3} [0-9]{4} [0-9]{4}$";
export let panCardPatternValidation = "^[A-Z]{5}[0-9]{4}[A-Z]{1}$";
export let drivingLicensePatternValidation =
  "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$";
export let visaCardPatternValidation = "^4[0-9]{0,}$";
export let americanCardPatternValidation = "^3[47][0-9]{0,}$";
export let masterCardPatternValidation = "5[1-5][0]?[0-9]{0,}$";
export let discoveryCardPatternValidation =
  "^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$";
export let passportPatternValidation = "[A-Z][1-9][0-9][1-9]*";
export let nonBookingInvaildDropdown = [
  "Redirect - Account Query",
  "Redirect - Invalid Customer",
  "Redirect - Additional Card Benefit",
  "Invalid – Call Dropped/Customer Hangup",
  "Invalid – Test/Junk Call/Email",
  "Invalid - Vendor Call",
  "Redirect - Credit Card Related Information & Benefits",
  "Redirect - Debit Card Related Information & Benefits",
  "Redirect - About CC DC Rewards points",
  "Redirect - Reward Points Redemption",
  "Redirect - About Bank Products and services",
];

export let acceptFileFormate = ".pdf,.png,.jpg";
export let FileallowedTypes = ["application/pdf", "image/png", "image/jpeg"];
export let non_concierge_service_id = ["10340", "22650"];

// -------------------ACL----------------------
export let permissionArray = [
  {
    key: "dashboard",
    value: {
      path: "/dashboard",
      title: "Dashboard",
      icon: "dashboard",
      class: "",
    },
  },
  {
    key: "my_profile",
    value: {
      path: "/my-profile",
      title: "My Profile",
      icon: "person",
      class: "",
    },
  },
  {
    key: "agent_management",
    value: {
      path: "/agent-list",
      title: "Agent Management",
      icon: "group",
      class: "",
    },
  },
  {
    key: "offline_booking",
    value: {
      path: "/offline-booking/user-registration",
      title: "Offline Booking",
      icon: "event_note",
      class: "",
    },
  },
  // { key: "upload_ticket", value: { path: '/Upload-Tickets', title: 'Upload Tickets', icon: 'upload', class: '' } },
  {
    key: "new_registration",
    value: {
      path: "/new-user-registration",
      title: "New User Registration",
      icon: "fa fa-user-plus",
      class: "",
    },
  },
  {
    key: "role_management",
    value: {
      path: "/role-creation",
      title: "Role Management",
      icon: "workspaces",
      class: "",
    },
  },
  {
    key: "user_feedback",
    value: {
      path: "/user-feedback",
      title: "User Feedback",
      icon: "feedback",
      class: "",
    },
  },
  // { key: "mis_dashboard", value: { path: '/mis-dashboard', title: 'MIS-Dashboard', icon: 'chat', class: '' } },
  {
    key: "reset_password",
    value: {
      path: "/reset-password",
      title: "Change Password",
      icon: "key",
      class: "hide-logout",
    },
  },
  {
    key: "log_out",
    value: { path: "/log-out", title: "Logout", icon: "logout", class: "" },
  },
];
export let defaultPermission = [
  "dashboard",
  "my_profile",
  "reset_password",
  "log_out",
];
export let role_permission_allowed_roles = ["developer", "admin"];
export let mis_permission_allowed = [
  "mis_interaction_report",
  "mis_rejection_report",
];
export let specificPermission = "role_management";
export let mis_permission = "mis_dashboard";
// export let interaction_report = "mis_interaction_report"
// export let rejection_report = "mis_rejection_report";
export let profiler_write = "profiler_drawer_write";
export let profiler_read = "profiler_drawer_read";
export let default_permission = [0, 1, 9, 10];
// ------------------------ACL-----------------------

if (!environment.production) {
  ServicesListobject = [
    {
      "122": "offline-booking/healthcare-service",
    },
    {
      "123": "delivery-of-essential-goods",
    },
    {
      "124": "offline-booking/groceries-delivery",
    },
    {
      "125": "offline-booking/daignostic-service",
    },
    {
      "127": "chauffer-service",
    },
    {
      "129": "offline-booking/appliance-repair",
    },
    {
      "10141": "offline-booking/table-reservation",
    },
    {
      "10022": "offline-booking/limited-edition",
    },
    {
      "174": "offline-booking/global-virtual-experiences",
    },
    {
      "10194": "offline-booking/global-travel",
    },
    {
      "131": "book-pickupanddrop",
    },
    {
      "133": "sourcing-of-luxury-items",
    },
    {
      "146": "covid-19-symptoms-checker",
    },
    {
      "149": "offline-booking/medical-consultation",
    },
    {
      "151": "medical-e-prescriptions",
    },
    {
      "154": "offline-booking/delivery-medicine",
    },
    {
      "156": "offline-booking/psychological-assistance",
    },
    {
      "158": "offline-booking/diet-nutrition-sessions",
    },
    {
      "160": "offline-booking/fitness",
    },
    {
      "162": "it-cyber-assistance",
    },
    {
      "164": "fuel-delivery-battery-jumpstart",
    },
    {
      "166": "offline-booking/vehicleservice",
    },

    {
      "168": "Gift, Flowers & Cake Delivery",
    },
    {
      "170": "personalised-meal-by-home-chefs",
    },
    {
      "172": "offline-booking/fashion-stylist",
    },
    {
      "426": "battery-jumpstart-service",
    },
    {
      "4179": "offline-booking/covid-assistance",
    },
    {
      "2804": "offline-booking/ambulance-booking",
    },
    {
      "3137": "offline-booking/travel-assistance",
    },
    {
      "4356": "offline-booking/covid-assistance",
    },
    {
      "2964": "offline-booking/ambulance-booking",
    },
    {
      "2965": "offline-booking/travel-assistance",
    },
    {
      "3066": "offline-booking/it-support",
    },
    {
      "3428": "offline-booking/it-support",
    },
    {
      "4849": "offline-booking/hospital-services",
    },
    {
      "9533": "offline-booking/airportservices",
    },
    {
      "9189": "offline-booking/golf-sessions",
    },
    {
      "9158": "offline-booking/physical-gym-session",
    },
    {
      "16460": "offline-booking/book-otherservice",
    },
    {
      "17366": "offline-booking/money-cannot-buy",
    },
    {
      "17629": "offline-booking/travel-stay",
    },
    {
      "26447": "offline-booking/health-saving-booking",
    },
    {
      "26483": "offline-booking/full-body-checkup",
    },
  ];
  otherServicesID = ["317", "318"];
  GOOGLE_MAPS_API_KEY = "AIzaSyB3e_-Fy3mqzzmDPGKzx3uMfg2rr1zTuog";
} else {
  ServicesListobject = [
    {
      "122": "offline-booking/healthcare-service",
    },
    {
      "123": "delivery-of-essential-goods",
    },
    {
      "124": "offline-booking/groceries-delivery",
    },
    {
      "125": "offline-booking/daignostic-service",
    },
    {
      "127": "chauffer-service",
    },
    {
      "129": "offline-booking/appliance-repair",
    },
    {
      "5214": "offline-booking/table-reservation",
    },
    {
      "5212": "offline-booking/limited-edition",
    },
    {
      "174": "offline-booking/global-virtual-experiences",
    },
    {
      "5216": "offline-booking/global-travel",
    },
    {
      "131": "book-pickupanddrop",
    },
    {
      "133": "sourcing-of-luxury-items",
    },
    {
      "146": "covid-19-symptoms-checker",
    },
    {
      "149": "offline-booking/medical-consultation",
    },
    {
      "151": "medical-e-prescriptions",
    },
    {
      "154": "offline-booking/delivery-medicine",
    },
    {
      "156": "offline-booking/psychological-assistance",
    },
    {
      "158": "offline-booking/diet-nutrition-sessions",
    },
    {
      "160": "offline-booking/fitness",
    },
    {
      "162": "it-cyber-assistance",
    },
    {
      "164": "fuel-delivery-battery-jumpstart",
    },
    {
      "166": "offline-booking/vehicleservice",
    },

    {
      "168": "Gift, Flowers & Cake Delivery",
    },
    {
      "170": "personalised-meal-by-home-chefs",
    },
    {
      "172": "offline-booking/fashion-stylist",
    },

    {
      "426": "battery-jumpstart-service",
    },
    {
      "4179": "offline-booking/covid-assistance",
    },
    {
      "2804": "offline-booking/ambulance-booking",
    },
    {
      "2965": "offline-booking/travel-assistance",
    },
    {
      "4356": "offline-booking/covid-assistance",
    },
    {
      "2964": "offline-booking/ambulance-booking",
    },
    {
      "2965": "offline-booking/travel-assistance",
    },
    {
      "3066": "offline-booking/it-support",
    },
    {
      "3428": "offline-booking/it-support", // here creating confusion
    },
    {
      "4479": "offline-booking/hospital-services",
    },
    {
      "5210": "offline-booking/airportservices",
    },
    {
      "5068": "offline-booking/golf-sessions",
    },
    {
      "5218": "offline-booking/physical-gym-session",
    },
    {
      "6266": "offline-booking/book-otherservice",
    },
    {
      "2889": "offline-booking/home-digital-protection",
    },
    {
      "2890": "offline-booking/lifestyle-advisor-video-consultation",
    },
    {
      "7623": "offline-booking/money-cannot-buy",
    },
    {
      "7670": "offline-booking/travel-stay",
    },
    {
      "11066": "offline-booking/health-saving-booking",
    },
    {
      "11093": "offline-booking/full-body-checkup",
    },
  ];
  otherServicesID = ["306", "307", "313"];
  GOOGLE_MAPS_API_KEY = "AIzaSyCgPth_adTA1EHf3NWKU9D5Ze61V9BC4Qk";
}
export let PrescriptionSubServiceId = ["22830", "8872"];
