import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/guard.service';
import { AppRoutingModule } from './app.routing';
import { OrderModule } from 'ngx-order-pipe';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './services/user.service';
import { JwtInterceptor } from './services/jwt.interceptors';
import { ErrorInterceptor } from './services/error.interceptors';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { AgmCoreModule} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {MatDialogModule} from '@angular/material/dialog';
import { GOOGLE_MAPS_API_KEY} from '../app/offline-booking/helpers/services';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { CounterDirective } from './timer.directive';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    OrderModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    AgmCoreModule.forRoot({
      // apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
      apiKey:'https://maps.googleapis.com/maps/api/js?key=AIzaSyCgPth_adTA1EHf3NWKU9D5Ze61V9BC4Qk&libraries=places&language=en'
    }),
    NgbModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ResetLinkComponent,
    MatConfirmDialogComponent,
    // OtherservicesComponent,
    CounterDirective


  ],
  exports: [
    HttpClientModule,
  ],
  providers: [UserService, AuthGuard, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents:[MatConfirmDialogComponent]
})
export class AppModule { }