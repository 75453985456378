import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'login',
    // pathMatch: 'full',
    component: LoginComponent,
  }, {
    path: 'login',
    component: LoginComponent,
  }, {
    path: 'reset-password',
    component: ResetPasswordComponent,
  }, {
    path: 'reset-link',
    component: ResetLinkComponent,
  }, {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }, {
    path: 'offline-booking',
    component: AdminLayoutComponent,
    loadChildren: () => import('./offline-booking/offline-booking.module').then(m => m.OfflineBookingModule),
  }, {
    path: 'kotak-offlinebooking',
    component: AdminLayoutComponent,
    loadChildren: () => import('./kotak-offline-booking/kotak-offline-booking.module').then(m => m.KotakOfflineBookingModule),
  },
  {
    path: 'role-creation',
    component: AdminLayoutComponent,
    loadChildren: () => import('./role-creation/role-creation.module').then(m => m.RoleCreationModule),
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
