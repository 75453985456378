import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { Subject,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private userLoggedIn = new Subject<boolean>();
  resolutionData: any;
  fraudData: any;
  statusdata: any;
  customerData: any;
  ticketDetail: any;

              Loungue = new BehaviorSubject([]);
               Dining = new BehaviorSubject([]);
            MeetGreet = new BehaviorSubject([]);
           TableReser = new BehaviorSubject([]);
       LimitedEdition = new BehaviorSubject([]);
      GlobalTravelExp = new BehaviorSubject([]);
   GlobalVirExpMaster = new BehaviorSubject([]);
      PhysicalGymSess = new BehaviorSubject([]);
          GolfSession = new BehaviorSubject([]);
     DomesticGolfSess = new BehaviorSubject([]);
InternationalGolfSess = new BehaviorSubject([]);
         DomesticLess = new BehaviorSubject([]);
           PickupDrop = new BehaviorSubject([]);
     MoneyExperiences = new BehaviorSubject([]);
        TravelandStay = new BehaviorSubject([]);
       onlinePharmacy = new BehaviorSubject([]);

       pickupdropInfo = this.PickupDrop.asObservable();
           longueInfo = this.Loungue.asObservable();
 DomesticGolfSessInfo = this.DomesticGolfSess.asObservable();
InternationalGolfInfo = this.InternationalGolfSess.asObservable();
     DomesticLessInfo = this.DomesticLess.asObservable();
       tableReserInfo = this.TableReser.asObservable();
           DiningInfo = this.Dining.asObservable();
        MeetGreetInfo = this.MeetGreet.asObservable();
   LimitedEditionInfo = this.LimitedEdition.asObservable();
 GlobalVirExpMaterInfo = this.GlobalVirExpMaster.asObservable();
   GlobalTravelExpInfo = this.GlobalTravelExp.asObservable();
   PhysicalGymSessInfo = this.PhysicalGymSess.asObservable();
       GolfSessionInfo = this.GolfSession.asObservable();
  MoneyExperiencesInfo = this.MoneyExperiences.asObservable();
     TravelAndStayInfo = this.TravelandStay.asObservable();
   HealthPharmacyInfo = this.onlinePharmacy.asObservable();

    constructor() {
      this.userLoggedIn.next(false);
   }

   setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }
  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
updateLoungue(data){
  this.Loungue.next(data)
   }
 updateDining(data){
  this.Dining.next(data)
   }
updateMeetGreet(data){
  this.MeetGreet.next(data)
  }
 updateTableReser(data){
   this.TableReser.next(data)
 }
updateLimiteEdiInfo(data){
  this.LimitedEdition.next(data)
}
updateGlobalVirExpInfo(data){
  this.GlobalVirExpMaster.next(data)
}
updatePhysicalGymSessInfo(data){
  this.PhysicalGymSess.next(data)
}
updateGolfSessionInfo(data){
  this.GolfSession.next(data)
}
updateIntrnationalInfo(data){
  this.InternationalGolfSess.next(data);
}
updateDomesticGolfSessInfor(data){
  this.DomesticGolfSess.next(data);
}
updateDomesticLessInfo(data){
  this.DomesticLess.next(data);
}
updateGlobalTravelExpInfo(data){
  this.GlobalTravelExp.next(data);
}
updateMoneyExperiencesInfo(data){
  this.MoneyExperiences.next(data);
}
updateTravelandStayInfo(data){
  this.TravelandStay.next(data);
}
updateHealthInfo(data){
  this.onlinePharmacy.next(data);
}
}
