import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfflineBookingService } from 'app/offline-booking/Offline-booking-Services/offline-booking.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  @ViewChild('f') form: NgForm;
  postData: any = {
    email: '',
    type: '',
  };
  loaderFlag: boolean;
  constructor(private _userServ: UserService, private router: Router, private OfflineBookingService: OfflineBookingService) { }
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
  ngOnInit(): void {
  }

  forgotPassword() {
    this.loaderFlag = true
    this.postData.type = 'forgetPassword'
    sessionStorage.setItem('otpData', (this.postData.email))
    this.OfflineBookingService.sendVarificationCode(this.postData).subscribe((otpRes) => {
      if (otpRes.message == "Email you have entered is validated." && otpRes.statusCode == 200) {
        this.router.navigate(['/reset-password'])
        alert('OTP has been sent to your email Id')
        this.loaderFlag = false
      } else {
        alert('Email you have entered not register with us')
        this.loaderFlag = false
      }

    }, (err) => {
      alert(err)
      this.loaderFlag = false
    })
  }
}
