import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-link',
  templateUrl: './reset-link.component.html',
  styleUrls: ['./reset-link.component.css']
})
export class ResetLinkComponent implements OnInit {

  constructor(private _userServ: UserService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {
  }

  

  goBack(){
    this.router.navigate(['login']);
  }
}
