<!DOCTYPE html>
<html>

<head>
	<!-- Required meta tags -->
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
	<link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet"
		id="bootstrap-css">
	<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet">
	<link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.3.0/css/datepicker.css" rel="stylesheet"
		type="text/css" />
	<link
		href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
		rel="stylesheet">
	<title>Europ Healthcare</title>
	<style>
		body {
			font-family: 'Source Sans Pro', sans-serif;
			color: #000;
		}
	</style>
</head>

 

<body class="login-page">
	<div class="container-fluid">
	<div class="col-12 col-sm-12 col-md-12 col-lg-12 hidden-xs tab-mb-20">
		<div class="logo-mobile-only mweb-left mweb-center m-t-40">
			<div class="logos web-logo-wrap">
				<div class="logo-1">
					<a href="#">
						<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
					</a>
				</div>
				<div class="logo-2">
					<a href="#">
						<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
					</a>
				</div>
			</div>
		</div>
	</div>
	</div>
	<div class="se-pre-con" *ngIf="loaderFlag" id="loader"></div>
	<div class="login-main clearfix">
		
		<div class="col-12 col-sm-6 col-md-6 col-lg-6 mweb-mt-30 hidden-xs">
		<div class="left-area mweb-mt-30">
			<div class="logos logi-hidden hidden-md hidden-sm hidden-lg">
				<div class="logo-1">
					<a href="#">
						<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
					</a>
				</div>
				<div class="logo-2">
					<a href="#">
						<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
					</a>
				</div>
			</div>

			<div class="login-img">
				<figure>
					<img src="../../../assets/img/family.svg" class="img-responsive" alt="login-image">
				</figure>
			</div>
		</div>
	</div>
		<!-- login form  -->
		<div class="col-12 col-sm-6 col-md-6 col-lg-6">
		<div class="right-area">
			<div class="form-login frm-lg-mrg">
				<div class="logo-mobile-only hidden-lg hidden-md hidden-sm visible-xs">
					<div class="logos web-logo-wrap">
						<div class="logo-1">
							<a href="#">
								<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
							</a>
						</div>
						<div class="logo-2">
							<a href="#">
								<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
							</a>
						</div>
					</div>
				</div>
				<div class="login-title">
					<h3 class="text-center ">Log in to your homecare account</h3>
				</div>
				<div class="clearfix ">
					<div *ngIf="isActiveError" class="login-error">
					Error :	{{isActiveErrorValue}}
					</div>
					<div *ngIf="error" class="login-error">
						Error :{{error}}
					</div>
				   </div>
				<!-- after password change -->
				<h3 class="text-center hidden">Successfully changed your password! Log in now</h3>
				<!-- after password change -->
				<form class="login-form-new tab-mt-20" (submit)="login()" #LoginForm="ngForm">

					<div class="form-group">
						<label for="username" class="lable-title">Email</label>
						<input type="username" class="form-control" placeholder="Username" autocomplete="off" required
							[(ngModel)]="username" name="uname" #uname="ngModel">
						<span class="glyphicon glyphicon-user form-control-feedback"></span>
						<div *ngIf="uname.errors && (uname.dirty || uname.touched)" class="alert alert-danger">
							<div [hidden]="!uname.errors.required">
								Username is required
							</div>
							<div [hidden]="!uname.errors.minlength">
								Username is required
							</div>
						</div>
					</div>

					<div class="form-group">
						<label for="password" class="lable-title">Password</label>
						<input type="password" class="form-control" placeholder="Password" autocomplete="off" required minlength="1"
							[(ngModel)]="password" name="passwd" #passwd="ngModel">
						<span class="glyphicon glyphicon-lock form-control-feedback"></span>
						<div *ngIf="passwd.errors && (passwd.dirty || passwd.touched)" class="alert alert-danger">
							<div [hidden]="!passwd.errors.required">
								Password is required
							</div>
							<div [hidden]="!passwd.errors.minlength">
								Invalid Password
							</div>
						</div>
						
					</div>

					<div class="clearfix">
						<div class="pull-left">
							<label class="remember">
								<span>Remember me</span>
								<input type="checkbox" checked="checked">
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="pull-right">
							<span (click)="activateFP()" class="forgot-password">Forgot password?</span>
						</div>
					</div>
					<button type="submit" [disabled]="isSubmit" [disabled]="!LoginForm.form.valid" class="btn btn-primary log-in-btn">Login</button>
					<p class="text-center member-login-page">
						Don’t have an account?<a href="#" class="signup-now">Sign up now.</a>
					</p>
				</form>				
			</div>

			<!--Reset Password-->
			<div class="form-login frm-lg-mrg" style="display: none;">
				<div class="logo-mobile-only hidden-lg hidden-md hidden-sm visible-xs">
					<div class="logos web-logo-wrap">
						<div class="logo-1">
							<a href="#">
								<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
							</a>
						</div>
						<div class="logo-2">
							<a href="#">
								<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
							</a>
						</div>
					</div>
				</div>
				<div class="login-title">
					<h3 class="text-center ">Reset Password</h3>
				</div>
				<div class="clearfix ">
					<div *ngIf="isActiveError" class="login-error">
					Error :	{{isActiveErrorValue}}
					</div>
					<div *ngIf="error" class="login-error">
						Error :{{error}}
					</div>
				   </div>
				<!-- after password change -->
				<h3 class="text-center hidden">Successfully changed your password! Log in now</h3>
				<!-- after password change -->
				<form (submit)="resetPassword()" class="login-form-new tab-mt-20"  >

					<div class="form-group">
						<label for="username" class="lable-title">Enter new password</label>
						<input type="username" class="form-control" placeholder="Enter here">
						<span class="glyphicon glyphicon-user form-control-feedback"></span>
						<div *ngIf="uname.errors && (uname.dirty || uname.touched)" class="alert alert-danger">
							<div [hidden]="!uname.errors.required">
								Username is required
							</div>
							<div [hidden]="!uname.errors.minlength">
								Username is required
							</div>
						</div>
					</div>

					<div class="form-group">
						<label for="username" class="lable-title">Enter confirm new password</label>
						<input type="username" class="form-control" placeholder="Enter here">
						<span class="glyphicon glyphicon-user form-control-feedback"></span>
						<div *ngIf="uname.errors && (uname.dirty || uname.touched)" class="alert alert-danger">
							<div [hidden]="!uname.errors.required">
								Username is required
							</div>
							<div [hidden]="!uname.errors.minlength">
								Username is required
							</div>
						</div>
					</div> 
					<button type="submit" class="btn btn-primary log-in-btn"  data-toggle="modal" data-target="#changepassword">SUBMIT</button>
					</form>
				
			</div>
		</div>
	</div>
	</div>
</body>

</html>