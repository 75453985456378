<nav class="navbar navbar-expand-lg navbar-white page-topbar">
    <div class="container-fluid">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 mweb-pad-0">
            <div class="navbar-wrapper">
                <a [routerLink]="['/dashboard']">
                <img src="https://eahomecare.in/wp-content/themes/homecare_new/assets/static/images/eahomecare-logo.png">
                </a>
                <!-- <a class="navbar-brand hidden" href="javascript:void(0)"><B>{{getTitle()}}</B></a>-->
               </div>
        </div>
        <div class="col-lg-2">
            <div class="collapse navbar-collapse justify-content-end" id="navigation">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                            <i class="material-icons">person</i> {{name}}
                            <!-- <p><span class="caret"></span></p>  -->
                        </a>

                        <ul class="dropdown-menu dropdown-menu-right">
                            <li data-turbolinks="false"> <a routerLink="/reset-password" routerLinkActive="active"><p> Change Password</p> </a>
                            </li>
                            <li data-turbolinks="false"> <a (click)="logout()"><p>Log Out</p> </a>
                            </li>
                        </ul><!-- <i class="material-icons">person</i>
                            <p>
                                <span class="d-lg-none d-md-block">Account</span>
                            </p>
                        </a> -->
                    </li>
                </ul>
            </div>
        </div>

    </div>
</nav>
<br>
<!-- <hr style="margin-top: 35px;"> -->

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->
