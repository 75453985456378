<!-- expandit -->
<button id="mwebmenu_icon" class="ellipsisclose "><i class="fa fa-bars " aria-hidden="true"></i></button>
<div class="page-sidebar expandit">
  <button id="menu_icon" class="ellipsisclose hidden-xs"><i class="fa fa-bars " aria-hidden="true"></i></button>
  <!-- <button id="menu_icon" class="ellipsisopen"><i class="fa fa-ellipsis-v " aria-hidden="true"></i></button>-->
  <div class="sidebar-inner m-t-60 mweb-mt-20" id="main-menu-wrapper">
    <ul class="wraplist" id="sideBarClose" style="height: auto;">
      <!-- <li class="menusection">Main</li>-->
      <li *ngFor="let menu of menuItems">
        <a *ngIf="menu?.title == 'Logout'?false:true && menu.icon=='fa fa-user-plus'?false:true && menu.icon=='key'?false:true"
          class="waves-effect" [routerLink]="[menu.path]" routerLinkActive="active" aria-expanded="false">
          <i class="material-icons">{{menu.icon}}</i> <span class="hide-menu"> {{menu.title}} </span></a>
        <a *ngIf="menu?.title == 'Logout'?false:true && menu.icon=='key'?true:false" class="waves-effect hide-logout"
          [routerLink]="[menu.path]" routerLinkActive="active" aria-expanded="false">
          <i class="material-icons">{{menu.icon}}</i> <span class="hide-menu"> {{menu.title}} </span></a>
        <a *ngIf="menu.title == 'Logout'?false:true && menu.icon=='fa fa-user-plus'?true:false" class="waves-effect"
          [routerLink]="[menu.path]" routerLinkActive="active" aria-expanded="false">
          <i class="fa fa-user-plus" aria-hidden="true" style="font-size: 22px;"></i><span class="hide-menu">
            {{menu.title}} </span></a>
        <a *ngIf="menu.title == 'Logout'?true:false && menu.icon=='logout'?true:false" class="waves-effect hide-logout"
          (click)="logout()" aria-expanded="false">
          <i class="fa fa-sign-out" aria-hidden="true" style="font-size: 22px; float: left;"></i><span
            class="hide-menu">
            {{menu.title}} </span></a>
      </li>
 
    </ul>
  </div>
</div>