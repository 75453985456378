<!DOCTYPE html>
<html>
      <head>
            <!-- Required meta tags -->
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
            <link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet">
            <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet">
            <!-- <link href="css/main-style.css" rel="stylesheet"> -->
            <title>Europ Healthcare</title>
      </head>
      <body class="login-page">
            <div class="login-main">
                  <div class="left-area">
                    <div class="logos hidden-sm hidden-xs">
                        <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                        <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                        
                  </div>
                        
                        <div class="login-img">
                            <figure>
                                <img src="../../../assets/img/family.svg" class="img-responsive" alt="login-image">
                          </figure>
                        </div>
                  </div>
                  <!-- reset password link alert -->
                  <div class="right-area " >
                        <div class="form-login reset-form">
                              <h3 class="text-center">Reset link has been sent</h3>
                              <form class="login-form-new reset-link-alert">
                                    <p class="text-center">An email with password reset link has been sent to your registered
                                    email address. Kindly follow the instructions to reset your password.</p>
                                    <button type="submit"  (click)= "goBack()" class="btn btn-primary save-btn">Go back</button> 
                              </form>
                              <div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
                                <div class="logos ">
                                      <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                                      <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                                </div>
                          </div>
                        </div>
                  </div>
                  <!-- reset password link alert -->
            </div>
      </body>
</html>