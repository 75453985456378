<!DOCTYPE html>
<html>

<head>
	<!-- Required meta tags -->
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
	<link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet"
		id="bootstrap-css">
	<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet">
	<link
		href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
		rel="stylesheet">
	<!-- <link href="css/main-style.css" rel="stylesheet"> -->
	<title>Europ Healthcare</title>
</head>

<body class="login-page">
	<div class="se-pre-con" *ngIf="loaderFlag" id="loader"></div>
	<div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
		<div class="logos">
			<div class="logo-1">
				<a href="#">
					<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
				</a>
			</div>
			<div class="logo-2">
				<a href="#">
					<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
				</a>
			</div>
		</div>
	</div>
	<div class="login-main clearfix">
		<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 top">
			<div class="logos hidden-sm hidden-xs">
				<div class="logo-1">
					<a href="#">
						<img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive">
					</a>
				</div>
				<div class="logo-2">
					<a href="#">
						<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
					</a>
				</div>
			</div>

			<div class="login-img ">
				<figure>
					<img src="../../../assets/img/family.svg" class="img-responsive hidden-xs" alt="login-image">
				</figure>
			</div>
		</div>

		<!-- reset password form -->
		<div class="col-12 col-sm-6 col-md-6 col-lg-6 mweb-pad-0">
			<!-- reset password form -->
			<div class="right-area">
				<div class="form-login">
					<h3 class="text-center">Reset your password</h3>
					<form class="login-form-new" [formGroup]="myForm">
						<div class="form-group revlt-post">
							<label for="username" class="lable-title mb-4">Enter validation code sent to your email
								address</label>
							<div class="revlt-post frm-title">
								<input [type]="showPassword ? 'text' : 'password'" formControlName="number" onlyNumber
									autocomplete="off" maxlength="6" minlength="6" class="form-control frm-title"
									placeholder="Enter validation code">
								<i alt="show" class="fa fa-eye eye-pos" (click)="showPassword = !showPassword"
									[class.hide]="showPassword"></i>
								<i alt="hide" class="fa fa-eye-slash eye-pos" (click)="showPassword = !showPassword"
									[class.hide]="!showPassword"></i>
								<div class="resendrevlt">
									<div class="time-tile">
										<ng-container counter [counter]="setting.timer"
											(value)="onCounterChange($event)">
											<div class="text-right">
												<button class="btn btnresend {{ setting.btnClass }}" [disabled]="counter != 0"
													(click)="ressendOtp()">
													Resend OTP
													<span *ngIf="counter != 0">
														<ng-container *ngIf="!setting.timerType">
															in {{ counter }} seconds.
														</ng-container>
														<ng-container *ngIf="setting.timerType">
															in {{ formatSecsToMins(counter) }} minutes
														</ng-container>
													</span>
												</button>
											</div>
										</ng-container>
									</div>
								</div>
							</div>

						</div>

						<div class="form-group revlt-post">
							<label>Create password</label>
							<i alt="show" class="fa fa-eye resendpos" (click)="showCreatePassword = !showCreatePassword"
								[class.hide]="showCreatePassword"></i>
							<i alt="hide" class="fa fa-eye-slash resendpos" (click)="showCreatePassword = !showCreatePassword"
								[class.hide]="!showCreatePassword"></i>
							<input formControlName="password" [type]="showCreatePassword ? 'text' : 'password'"
								class="form-control" minlength="8" maxlength="16" autocomplete="off"
								pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$">
							
							<div *ngIf="f.password.touched && f.password.invalid" class="imp alert-danger">
								<div *ngIf="f.password.errors.required">Password is required.</div>
							</div>
							<div class="imp alert-danger" *ngIf="myForm.controls['password'].errors?.pattern">Please
								Enter Valid Password</div>
							<!-- <div class="alert alert-danger" *ngIf="myForm.get('password').hasError('passwordStrength')">
							{{myForm.get('password').errors['passwordStrength']}}
						</div> -->
						</div>
						<div class="form-group">
							<label>Confirm password</label>
							<input formControlName="confirm_password" [type]="showConfirmPassword ? 'text' : 'password'"
								class="form-control" minlength="8" maxlength="16" autocomplete="off"
								pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$">
							<i alt="show" class="fa fa-eye resendpos" (click)="showConfirmPassword = !showConfirmPassword"
								[class.hide]="showConfirmPassword"></i>
							<i alt="hide" class="fa fa-eye-slash resendpos" (click)="showConfirmPassword = !showConfirmPassword"
								[class.hide]="!showConfirmPassword"></i>
							<div *ngIf="f.confirm_password.touched && f.confirm_password.invalid"
								class="imp alert-danger">
								<div *ngIf="f.confirm_password.errors.required">Password is required.</div>
								<div *ngIf="f.confirm_password.errors.confirmedValidator">
									Password and Confirm Password must be match.
								</div>
							</div>
						</div>

						<button type="submit" [disabled]="!myForm.valid" class="btn btn-primary save-btn"
							(click)="resetPassword()">Save</button>
					</form>

					<div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
						<div class="logos">
							<div class="logo-1">
								<a href="#">
									<img src="../../../assets/img/home-care.svg" alt="home-care-logo"
										class="img-responsive">
								</a>
							</div>
							<div class="logo-2">
								<a href="#">
									<img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive">
								</a>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- reset password form -->
	</div>
</body>

</html>