import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { Router ,RouterModule} from '@angular/router';
import { permissionArray, specificPermission, mis_permission_allowed, mis_permission, default_permission, defaultPermission, role_permission_allowed_roles  } from 'app/offline-booking/helpers/services';
//declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  role: string;
  permissionArray = permissionArray;
  defaultPermission = defaultPermission;
  role_permission_allowed_roles = role_permission_allowed_roles;
  specificPermission = specificPermission;
  mis_permission_allowed = mis_permission_allowed;
  mis_permission = mis_permission;
  default_permission = default_permission;
  roleList: any;
  selected: any;
  permissionList = [];
  isPermissionAllow: boolean = false;
  constructor(private router: Router) { }



  ngOnInit() {
    this.setPermissionFunction();
    $(document).ready(function () {
      $('#menu_icon',).click(function () {
        if ($('.page-sidebar').hasClass('expandit')) {
          $('.page-sidebar').addClass('collapseit');
          $('.page-sidebar').removeClass('expandit');
          $('.profile-info').addClass('short-profile');
          $('.logo-area').addClass('logo-icon');
          $('#main-content').addClass('sidebar_shift');
          $('.menu-title').css("display", "none");
        } else {
          $('.page-sidebar').addClass('expandit');
          $('.page-sidebar').removeClass('collapseit');
          $('.profile-info').removeClass('short-profile');
          $('.logo-area').removeClass('logo-icon');
          $('#main-content').removeClass('sidebar_shift');
          $('.menu-title').css("display", "inline-block");
        }
      });
      $('#mwebmenu_icon',).click(function () {
        if ($('.page-sidebar').hasClass('expandit')) {
          $('.page-sidebar').addClass('collapseit');
          $('.page-sidebar').removeClass('expandit');
          $('.profile-info').addClass('short-profile');
          $('.logo-area').addClass('logo-icon');
          $('#main-content').addClass('sidebar_shift');
          $('.menu-title').css("display", "none");
        } else {
          $('.page-sidebar').addClass('expandit');
          $('.page-sidebar').removeClass('collapseit');
          $('.profile-info').removeClass('short-profile');
          $('.logo-area').removeClass('logo-icon');
          $('#main-content').removeClass('sidebar_shift');
          $('.menu-title').css("display", "inline-block");
        }
      });

      $('#menu_iconcls',).click(function () {
        if ($('.page-sidebar').hasClass('expandit')) {
          $('.page-sidebar').addClass('collapseit');
          $('.page-sidebar').removeClass('expandit');
          $('.profile-info').addClass('short-profile');
          $('.logo-area').addClass('logo-icon');
          $('#main-content').addClass('sidebar_shift');
          $('.menu-title').css("display", "none");
        } else {
          $('.page-sidebar').addClass('expandit');
          $('.page-sidebar').removeClass('collapseit');
          $('.profile-info').removeClass('short-profile');
          $('.logo-area').removeClass('logo-icon');
          $('#main-content').removeClass('sidebar_shift');
          $('.menu-title').css("display", "inline-block");
        }
      });
       $('#sideBarClose',).click(function () {
        if ($('.page-sidebar').hasClass('collapseit')) {
          $('.page-sidebar').addClass('expandit');
          $('.page-sidebar').removeClass('collapseit');
          $('.profile-info').removeClass('short-profile');
          $('.logo-area').removeClass('logo-icon');
          $('#main-content').removeClass('sidebar_shift');
          $('.menu-title').css("display", "inline-block");
        }
      }); 
    });

    this.role = localStorage.getItem('UserType')
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    localStorage.setItem("allowed_permission_list", JSON.stringify(this.permissionList))
    console.log(this.permissionList,"line 128")
    if (this.permissionList.length > 0 && this.isPermissionAllow) {
      let permissionData = [];
      permissionArray.map((service) => {
        this.permissionList.map((element) => {
          if (element == service.key) {
            permissionData.push(service.value)
          }
        })
      })
      this.menuItems = permissionData;
      console.log(this.menuItems,"line 138")
    } else {
      let permissionData = [];
      this.default_permission.map((service) => {
        permissionData.push(this.permissionArray[service].value);
      })
      this.menuItems = permissionData;
    }

    // if (this.role == "Agent") {
    //   this.menuItems = this.Agent;
    // }
    // else if (this.role == "TeamLead") {
    //   this.menuItems = this.TeamLead;
    // }
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  logout() {
		localStorage.clear();
		this.router.navigate(['login']);
	}

  setPermissionFunction() {
    if (JSON.parse(localStorage.getItem('permissionList'))?.length > 0) {
      this.isPermissionAllow = JSON.parse(localStorage.getItem('permissionList')).length > 0 ? true : false
      this.permissionList = JSON.parse(localStorage.getItem('permissionList'))
      defaultPermission.map((element) => {
        if (!this.permissionList.includes(element)) {
          this.permissionList.push(element)
        }
      })
    }
    if (JSON.parse(localStorage.getItem('roleList'))?.length > 0) {
      this.roleList = JSON.parse(localStorage.getItem('roleList'));
      this.roleList.map((element) => {
        if (this.role_permission_allowed_roles.includes(element)) {
          if (!this.permissionList.includes(this.specificPermission)) {
            this.permissionList.push(this.specificPermission)
          }
        }
      })
    } if (this.permissionList.length > 0) {
      this.mis_permission_allowed
      this.mis_permission_allowed.map((element) => {
        if (this.permissionList.includes(element) && !this.permissionList.includes(this.mis_permission)) {
          this.permissionList.push(this.mis_permission)
        }
      })
    }
  }
}
