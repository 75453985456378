// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { AbstractControl, ValidationErrors } from "@angular/forms";
export const environment = {
  // Demo
  //  api_base_url: "http://15.207.45.210:5000/",
  //  ext_url: "https://homecaredemo.europassistance.in/",


  // crm-live  We need to uncomment this when we go for live
  // base_url : "https://crm.eahomecare.in/"


  // UAT
    api_base_url: "https://api.eahomecare.in/",
   ext_url: " https://eahomecare.in/",
  //  base_url :"https://crm-stage.eahomecare.in/"


//  for me as of now
//  api_base_url:"https://crmapi-stage.eahomecare.in/",
//       ext_url:"https://stage.eahomecare.in/",
      // api_base_url: "http://localhost:5000/",
    // base_url: "https://crm-stage.eahomecare.in/",
      // base_url: "http://localhost:4200/",
  production: true,
  isEnabledUnregisteredUser :false,
  isCustomerProfilerVisible : true,
  // customerProfilerUrl: 'https://custprofileapi.europ-assistance.in',
  customerProfilerUrl: 'https://profilerapi.europ-assistance.in',
  profilerKey:"eyJhbGciOiJIUzI1NiJ9.MDFlMmM4NDE0MjdmMzZjNWFiZjhjZTQ4YzQyMmE5Nzc6M2ZiOTE5OTI1MWY4NGNhOGQzYWM1N2RhMTY3YmU0ODFjYTZjY2Q0NzNhZmVjNjUxZDRmNzgzMTU2ZjQ0OTQwMTQ4OTZjODgyN2Q2ZWY4MzkyOTg2Y2ZmODc5OTdlZDlkNjVmZTczNGM2ZGRkMzU1MmU3MDA3MGEyZDcxN2FhNTk3YzM0MmU3MzlkYzYwOTQ3NjQyOGY3NTVjNzViNjE5NTY5OGYxMjYzMGFkNjQ0Y2NjYTIzMGExN2NiNDc2MDRmODgxMDI1NmQyZjM5NmQ5MmU3YzM3MjczNDhlMDVlNmRhNGZhNzIyNzA0ZTQwNmZjNDBjMDcwOGRlYTc2MzIwYWRkMTE2ZGNkYTc3MzAyNGRhZmFlMWZiNzJlZjRmYzk4ZjYwNGFjZGY4ZTUzYWJiM2M1MGIyYTE3NjBlNzYyZjMyZjQ2MjU3ZmUzNDk3NGQ5NGM5ZDQ0ZDM3NTE1MGJjOA.g__4d265FBrDlFMfHjMXCXudY1ZRiJF6vT6ItPdAI_c",
  profilerToken: 'Basic bmJqRnFwdEk1K1IzK1BSWjV6bTZWZ2dwYmhNL01xKzVWS05iTlFQOUFMbz0='

};
export let PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';

  if (!value) {
    return null
  }
  let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `Upper case required` };
  }

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `lower case required` };
  }


  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `number required` };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `Special char required` };
  }
  if (value.length < 8) {
    return { passwordStrength: 'Password should be at least 8 characters long' };
  }
  return null;
}
