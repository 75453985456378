import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { data } from "jquery";
import { LoginComponent } from "app/login/login.component";
@Injectable({
  providedIn: "root",
})
export class OfflineBookingService {
  headers: any;
  user: any;
  private extUrl = environment.ext_url;
  private baseurl = environment.api_base_url;
  private profilerUrl = environment.customerProfilerUrl;
  profilerHeaderskeywords:any
  profilerHeaders = {
    Authorization: environment?.profilerToken,
    "Content-Type": "application/json",
    "Accept": "application/json "
  }
  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.headers = {
      Authorization: "Token " + this.user?.token,
      "Content-Type": "application/json",
    };
  }
  // getToken() {
  //   this.user = JSON.parse(localStorage.getItem('currentUser'));
  //   this.headers = {
  //     Authorization: "Token " + this.user.token,
  //     "Content-Type": "application/json"
  //   }
  //   return this.headers;
  // }

  getBaseToken() {
    this.profilerHeaderskeywords = {
      Authorization: `Token ${btoa(sessionStorage.getItem('loginProfileToken'))}`,
      "Content-Type": "application/json",
      "Accept": "application/json "
    }
    let lastChar = this.profilerHeaderskeywords.Authorization.substr(this.profilerHeaderskeywords.Authorization.length - 1);
    if (lastChar != "=") {
      this.profilerHeaderskeywords.Authorization = this.profilerHeaderskeywords.Authorization + "=";
    }
    return this.profilerHeaderskeywords
  }
  getNewBaseToken() {
    this.profilerHeaderskeywords = {
      Authorization: `Token ${btoa(sessionStorage.getItem('loginProfileToken'))}`,
      "Content-Type": "application/json",
      "Accept": "application/json ",
      "crm": "HC_CRM",
      "agentID": localStorage.getItem("userID")
    }
    let lastChar = this.profilerHeaderskeywords.Authorization.substr(this.profilerHeaderskeywords.Authorization.length - 1);
    if (lastChar != "=") {
      this.profilerHeaderskeywords.Authorization = this.profilerHeaderskeywords.Authorization + "=";
    }
    return this.profilerHeaderskeywords
  }
   customerProfilerGetCampaignLists(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/list-campaigns`, datakey, {
        headers: this.getBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  customerProfilerLogin(data) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/login`, data, {
        headers: this.profilerHeaders,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  customerProfilerRegister(data) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/register`, data, {
        headers: this.profilerHeaders,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  saveAuthentication(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/agent-management/saveAuthenticationKeyForAgent`, senddata, {
        headers: this.getToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  customerProfilergetKeywords(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/customer-info`, datakey, {
        headers: this.getNewBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  customerProfilergetSearchKeywords(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/search`, datakey,{
        headers: this.getNewBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  customerProfilergetQuestions(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/questions`, datakey,{
        headers: this.getNewBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  customerProfilergetSendCampaignNotification(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/link-campaign`, datakey,{
        headers: this.getBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  customerProfilerSubmitData(datakey) {
    return this.http
      .post<any>(`${this.profilerUrl}/api/v4/submit`, datakey,{
        headers: this.getNewBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  listOfProducts() {
    return this.http.get<any>(`${this.baseurl}api/products/allProducts`).pipe(
      map((data) => {
        return data;
      }),
      catchError((errorRes) => {
        // console.log(errorRes)
        let errormessage = "An error Occured";
        if (!errorRes.error || !errorRes.error.message) {
          return throwError(errormessage);
        }
        return throwError(errorRes);
      })
    );
  }

  getHealthSavingStateCityLocation(){
    return this.http.get<any>(`${this.extUrl}wp-json/wl/v52/get_all_locations`).pipe(
      map((data) => {
        return data;
      }),
      catchError((errorRes) => {
        // console.log(errorRes)
        let errormessage = "An error Occured";
        if (!errorRes.error || !errorRes.error.message) {
          return throwError(errormessage);
        }
        return throwError(errorRes);
      })
    );
  }

  destinationApi() {
    return this.http
      .post<any>(`${this.baseurl}api/destination/destinationInfo`, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  viatorapi() {
    return this.http
      .post<any>(`${this.baseurl}api/products/dumpProductDetails`, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  loadPlans() {
    return this.http.get<any>(`${this.extUrl}wp-json/wl/v34/ea_get_plans`).pipe(
      map((data) => {
        return data;
      }),
      catchError((errorRes) => {
        // console.log(errorRes)
        let errormessage = "An error Occured";
        if (!errorRes.error || !errorRes.error.message) {
          return throwError(errormessage);
        }
        return throwError(errorRes);
      })
    );
  }

  getServices() {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v2/all-services`, "")
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  getServicesBasedOnCid(cid) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v3/services-for-customer`, cid)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  getMemberBenefitCounts(sendData) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v33/get_user_member_benefit_utilization_count`,
        sendData
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getCustomeSubServiceSetting(data) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v37/get_custom_subservice_settings`, data)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }

  searchCustomer(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/searchCustomer`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  createNewCustomer(senddata,header) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/register-customer`, senddata, {
        headers: header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  validateEmailAndMobileNumber(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/checkCustomerData`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  getHomecareUserRegister(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v32/api_register_user`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getExperts(senddata) {
    let data = new FormData();
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v4/services-experts`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getFitnessSubService(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/23/get_online_fitness_childservices`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getfitnessSlots(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/24/get_fitness_session_slots`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getDateTimeSlots(senddata) {
    return this.http
    .post<any>(`${this.extUrl}wp-json/wl/v7/date-time-slots`, senddata, {
      headers: this.headers,
    })
    .pipe(
      map((data) => {
        return data;
      }),
      catchError((errorRes) => {
        // console.log(errorRes)
        let errormessage = "An error Occured";
        if (!errorRes.error || !errorRes.error.message) {
          return throwError(errormessage);
        }
        return throwError(errorRes);
      })
      );
    }
    availabilityBinRangeNumber(senddata) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/ea/v31/check_bin_number`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getprices(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v11/eahomecare_get_service_benefit_prices`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getFamilyMembers(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/familyMembers/getFamilyMembers`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //Verify regCode

  verifyRegCode(code) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v17/eahomecare_verify_regCode`,
        code,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getServiceTerms(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v16/eahomecare_get_service_terms`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  createCustomer(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/create`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  createCustomerforHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v2/register_user_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  saveAppointment(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/book-consulation/saveAppointments`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  sendAppointmentToHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v1/confirm_booking_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  fitnessAppointmentToHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v25/confirm_fitness_booking_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //https://uat.eahomecare.in/wp-json/wl/v18/eahomecare_get_user_address_list
  getAddress(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v18/eahomecare_get_user_address_list`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  ///wp-json/ea/v7/confirm_nutritionist_booking_from_crm
  saveDietandNutrition(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v7/confirm_nutritionist_booking_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //http://localhost/ea/code/wp-json/ea/v5/save_address_of_user_from_crm
  saveAddress(value) {
    // let dataObj = {
    //   customer_id: localStorage.getItem('customer_id'),
    //   latitude: addressDetails.lat,
    //   longitude: addressDetails.lng,
    //   is_default: 'no',
    //   type: value.type,
    //   cname: value.cname,
    //   currLoc: address,
    //   flat: value.flat,
    //   landmark: value.landmark,
    //   pincode: addressDetails.postal_code,
    //   zipcode: addressDetails.postal_code,
    //   city: addressDetails.locality,
    //   state: addressDetails.administrative_area_level_1,
    //   country: addressDetails.country,
    //   addressString: value.flat + "," + value.landmark + "," + "," + value.currLoc + "," + addressDetails.country + "," + addressDetails.postal_code,
    // }
    // console.log(dataObj, 'dataObj')
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v5/save_address_of_user_from_crm`,
        value,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  deleteAddress(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v28/delete_address_of_user_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateAddress(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v27/edit_address_of_user_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  convertPlaceToFriendlyObject(place) {
    var result = undefined;
    if (place) {
      result = {};
      result.postal_code = "";
      for (var i = 0, l = place.address_components.length; i < l; i++) {
        if (i == 0) {
          result.searchedBy = place.address_components[i].types[0];
        }
        result[place.address_components[i].types[0]] =
          place.address_components[i].long_name;
        //code related to save PIN
        if (place.address_components[i].types[0] == "postal_code") {
          result.postal_code = place.address_components[i].long_name;
        }
      }
      result.formattedAddress = place.formatted_address;
      result.lat = place.geometry.location.lat();
      result.lng = place.geometry.location.lng();
    }
    return result;
  }

  getOrderPrices(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v19/eahomecare_get_service_order_benefit_prices`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getCalculatedPrice(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v25/delivery_services_price_calculator`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //http://uat.eahomecare.in/wp-json/ea/v3/save_vehiclecare_from_crm

  saveVehiclecareService(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v3/save_vehiclecare_from_crm`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///api/book-order/saveVehiclecare
  saveOrdertoCrm(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/book-order/saveVehiclecare`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //api/book-healthcare/saveHealthcare
  saveHealthCaretoCrm(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/book-healthcare/saveHealthcare`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///wp-json/ea/v4/save_healthcare_services
  saveHealthcareService(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v4/save_healthcare_services`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///api/orders/saveOrders
  SaveOrderDatatoCrm(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/orders/saveOrders`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  SaveOrderDatatoCrmForAirport(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/airportExperience/saveAirportExperience`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // http://localhost/code/wp-json/ea/v16/save_appliance_repair_maintainence
  saveApplianceRepairService(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v16/save_appliance_repair_maintainence`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateMedicalDetails(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v26/crm_update_user_medical_details`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateCRMMedicalInfo(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/update`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // http://localhost/code/wp-json/ea/v12/save_itsupport_services
  saveItsupportService(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v12/save_itsupport_services`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // api/ITSupportService/saveITSupportService
  createITSupporttoCrm(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/ITSupportService/saveITSupportService`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///wp-json/ea/v6/save_order_data
  SaveOrderData(sendData) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/ea/v6/save_order_data`, sendData, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  addMoreFamilyMember(sendData) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v49/save_family_member`, sendData, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  ///api/book-consulation/update

  updatePostIdAndOrderId(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/book-consulation/update`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  fitnessUpdate(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/book-consulation/fitnessUpdate`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //wp-json/ea/v20/ea_document_uploads

  getDocumentUploads(senddata) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v20/ea_document_uploads`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // https://uat.eahomecare.in/wp-json/ea/22/get_vehicle_service_subservices
  getFuels(senddata) {
    // console.log(senddata, 'subsadfka')
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/22/get_vehicle_service_subservices`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // https://uat.eahomecare.in/wp-json/ea/21/get_appliance_service_subservices
  gettypeOfAppliance(senddata) {
    // console.log(senddata, 'subsadfka')
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/21/get_appliance_service_subservices`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //kotak related services================================================

  createKotakEmployee(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/kotak-customer/create`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //api/kotak-booking/createBooking
  saveKotakBookingCrm(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/kotak-booking/createBooking`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getKotakEmployees() {
    return this.http
      .get<any>(`${this.baseurl}api/kotak-customer/kotakCustomerList`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getKotakBookings() {
    return this.http
      .get<any>(`${this.baseurl}api/kotak-booking/kotakBookingList`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // end ========kotak related services========================================

  //Agent Apis======================================================
  ///api/agent-management/create
  createAgent(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/agent-management/create`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // api/agent-management/update
  updateAgents(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/agent-management/update`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //api/agent-management/getAgents

  getAgents(senddata,callTypeValue,header) {
    return this.http
      .get<any>(`${this.baseurl}api/agent-management/getAgents?role=${senddata}?callType=${callTypeValue}`, {
        headers: header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  //Agent api End=========================================

  //save deposition status

  //wl/v21/ea_status_update

  updateDepositionStatus(senddata) {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v21/ea_status_update`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ////Price calculator api for delivery of groceries/medicines offline payment

  sendPaymentForDelivery(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v25/delivery_services_price_calculator`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //updating call history
  ///api/tickets/updateCallHistory
  updateCallDetails(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/tickets/updateCallHistory`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateDeliveryOfMedicines(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/orders/updateDeliveryofMedicines`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //http://localhost:5000/api/covidRemoteAssistance//updateCovidRemoteAssistance
  updateCovidassistanceSessions(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/covidRemoteAssistance/updateCovidRemoteAssistance`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  // Get locations api //http://uat.eahomecare.in/wp-json/wl/v28/get_homecare_locations

  getLocations() {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v28/get_homecare_locations`, "", {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getAirportsNames() {
    return this.http
      .get<any>(`${this.extUrl}wp-json/wl/v39/mg_get_airport_names`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  //wp-json/wl/v47/get_homecare_all_banks
  getStatesList() {
    return this.http
      .get<any>(`${this.extUrl}wp-json/wl/v48/get_homecare_all_states`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getBanksList() {
    return this.http
      .get<any>(`${this.extUrl}wp-json/wl/v47/get_homecare_all_banks`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getCountryNames() {
    return this.http
      .get<any>(`${this.extUrl}wp-json/wl/v46/get_homecare_all_countries`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  //get Countries //http://uat.eahomecare.in/wp-json/wl/v29/get_homecare_countries

  getCountries() {
    return this.http
      .post<any>(`${this.extUrl}wp-json/wl/v29/get_homecare_countries`, "", {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //Send otp to verify customer registration //wp-json/wl/v30/get_otp_offline_registration
  getCustVerifyOtp(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v30/get_otp_offline_registration`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///api/tickets/getCovidAssistanceAllTickets  get covid assistance related tickets

  getCovidasstTickets(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/tickets/getCovidAssistanceAllTickets`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //save Covid Remote assistance //saveCovidRemoteAssistance

  saveCovidAssistanceBooking(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/covidRemoteAssistance/saveCovidRemoteAssistance`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //saving Covidassistance to homecare //ea/v9/save_covid_assistance_order

  saveCovidassistanceToHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v9/save_covid_assistance_order`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //uploadFile to Crm ///api/comman/docUpload

  fileUploadToCrm(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/comman/docUpload`, senddata)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///wp-json/wl/v31/get_ambulance_distance_calculated_details
  getAmbulanceCost(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v31/get_ambulance_distance_calculated_details`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  //ea/v11/save_ambulance_booking_offlinebooking
  saveAmbulanceBookingHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v11/save_ambulance_booking_offlinebooking`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //https://uat.eahomecare.in/wp-json/wl/v33/ea_user_membership_details
  getMembershipDetails(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v33/ea_user_membership_details`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  ///wp-json/wl/v32/get_homecare_airports
  getAirportLocations(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v32/get_homecare_airports`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //wp-json/ea/v10/save_travel_assistance_offlinebooking

  saveTravelAssistanceorder(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v10/save_travel_assistance_offlinebooking`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  //api/travelAssistance/saveTravelAssistance
  saveTravelAssistanceBooking(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/travelAssistance/saveTravelAssistance`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  sendPaymentLinkTravelAssistance(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/travelAssistance/updateTravelAssistance`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  saveHospitalserviceToHomecare(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/ea/v14/save_hospital_booking_offlinebooking`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  saveHosiptalServiceToCrm(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/hospital-services/createOrder`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getHomeDigitalProtection(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/cyberior/getDetails`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getLoungeDiningInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getPickandDropInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getMoneyExperiencesInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getTravelAndStayInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getLimitedEdiInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getDomesticGolfInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getDomesticLessionInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getInternationaGolfInfo(sendData) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_postdata_by_posttype`,
        sendData,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getHealthSavingfInfo(sendData) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v51/get_health_savings_sub_terms`,
        sendData,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getHealthTestType(data){
    return this.http
    .post<any>(
      `${this.extUrl}wp-json/wl/v51/get_health_savings_sub_terms`,
      data,
      { headers: this.headers }
    )
    .pipe(
      map((data) => {
        return data;
      }),
      catchError((errorRes) => {
        // console.log(errorRes)
        let errormessage = "An error Occured";
        if (!errorRes.error || !errorRes.error.message) {
          return throwError(errormessage);
        }
        return throwError(errorRes);
      })
    );
  }
  getTableReservationInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v37/get_all_table_reservations`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  getmeetgreetInfo(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v38/get_airport_products_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getpickdropCarPrice(senddata) {
    return this.http
      .post<any>(
        `${this.extUrl}wp-json/wl/v38/get_airport_products_posttype`,
        senddata,
        { headers: this.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  checkCustomer(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/customers/checkCustomerData`, senddata, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
  updateAgentStatus(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/agent-management/updateAgentStatus`,
        senddata,
        { headers: this.headers}
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  ValidateTicketDetails(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/tickets/validateTicketDetails`,
        senddata,
        { headers: this.headers}
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  sendVarificationCode(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/resetPassword/sendVerificationCode`, senddata)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }
  changePassWordApi(senddata) {
    return this.http
      .post<any>(`${this.baseurl}api/resetPassword/changePassword`, senddata)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getToken() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = {
      Authorization: "Token " + this.user?.token,
      "Content-Type": "application/json"
    }
    return this.headers;
  }

  getAllPermissionList(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/permissions/getAllPermissions`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getRoleData(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/roles/getRole`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateRole(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/roles/update`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  createNewRole(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/roles/create`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  getAllRoleList(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/roles/getAllRoles`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  updateRoleStatus(senddata) {
    return this.http
      .post<any>(
        `${this.baseurl}api/roles/updateStatus`,
        senddata,
        { headers: this.getToken() }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
  }

  registerCrmCustomer(data){
    return this.http
      .post<any>(`${this.profilerUrl}/customers/add/customer/hc/crm`, data, {
        headers: this.getBaseToken()
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errormessage);
        })
      );
  }
}
