import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private user: UserService) {
    // this.user.currentUser.subscribe((user: User) => {
    //   console.log('userauth', user)
    //   this.connected = user.connected;
    // });
  }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   // const currentUser = this.user.currentUserValue;
  //   console.log('route', route);
  //   // console.log('currentUser', currentUser);
  //   // if (currentUser) {
  //   //   if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
  //   //     // role not authorised so redirect to home page
  //   //     this.router.navigate(['/']);
  //   //     return false;
  //   //  }
  //   //   return true;
  //   // }
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return true;
   }
}

