import { Component, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SharedService } from './services/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('childModal', { static: false }) childModal
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  popupFlag: boolean;
  constructor(private idle: Idle, private keepalive: Keepalive,private router: Router,private appService:SharedService,public dialog: MatDialog) {  }

  ngOnInit(){
    if(localStorage.getItem('reloadFlag')) {
    this.idle.setIdle(910);
    this.stay()
   }
     this.idle.setIdle(900);
     this.idle.setTimeout(10);
     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
     this.idle.onIdleEnd.subscribe(() => {
     this.idleState = 'No longer idle'
     this.reset();
     });
     this.idle.onTimeout.subscribe(() => {
     this.idleState = 'Timed out';
     this.timedOut = true;
     this.logout();
     });
     this.idle.onIdleStart.subscribe(() => {
     this.idleState = 'You\'ve gone idle!'
     });
     this.idle.onTimeoutWarning.subscribe((countdown) => {
     this.idleState = 'You will log out in ' + countdown + ' seconds!'
     this.popupFlag = true
     });
     this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
     this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
       if (userLoggedIn) {
         this.idle.watch()
         this.timedOut = false;
       } else {
         this.idle.stop();
        }
     })
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
  stay() {
    this.reset();
    this.popupFlag = false
  }
  logout() {
    this.popupFlag = false
    this.appService.setUserLoggedIn(false);
    localStorage.removeItem('token')
    this.router?.navigate(['/']);
  }
}
