import { Component, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from './confirmed.validator';
import { CounterDirective } from 'app/timer.directive';
import { OfflineBookingService } from 'app/offline-booking/Offline-booking-Services/offline-booking.service';
import { MatDialogServiceService } from 'app/offline-booking/Offline-booking-Services/mat-dialog-service.service';
import { PasswordStrengthValidator } from 'environments/environment'
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  @ViewChildren(CounterDirective) CounterDirective;
  @Output() onValueChange = new EventEmitter<any>();
  myForm: FormGroup = new FormGroup({});
  id: string;
  postData: any = {
    type: ''
  };
  userInfo: any;
  public showPassword: boolean;
  public showCreatePassword: boolean;
  public showConfirmPassword: boolean;
  public showPasswordOnPress: boolean;
  public setting = {
    length: 4,
    numbersOnly: true,
    timer: 60,
    timerType: 1,
    btnClass: ''
  }
  public counter: number;
  otpInfo: any;
  displayTitle: any;
  binPopupFlag: any = 'resetpassword';
  loaderFlag: boolean;
  constructor(private _userServ: UserService, private router: Router, private fb: FormBuilder, private OfflineBookingService: OfflineBookingService, private dialogService: MatDialogServiceService) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('currentUser'));
    this.otpInfo = sessionStorage.getItem('otpData')
    this.myForm = this.fb.group({
      password: ['', [Validators.compose([Validators.required, PasswordStrengthValidator])]],
      confirm_password: ['', [Validators.required]],
      number: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    })
  }

  get f() {
    return this.myForm.controls;
  }
  public onCounterChange(e): void {
    this.counter = e;
    if (this.counter == 0) {
      this.onValueChange.emit(-1);
    }
  }
  resetPassword() {
    this.myForm.valid
    this.loaderFlag = true
    let data = {
      "email": this.otpInfo,
      "type": 'forgetPassword',
      "resetPasswordVerificationCode": this.myForm.value.number,
      "newPassword": this.myForm.value.password,
      "confirmPassword": this.myForm.value.confirm_password
    }
    this.OfflineBookingService.changePassWordApi(data).subscribe((res) => {
      if (res.message == "User password updated. " && res.statusCode == 200) {
        this.dialogService.OpenConfirmDialog(this.displayTitle, this.binPopupFlag).afterClosed().subscribe(res => {
          if (res) {
            this.router.navigate(['/login'])
          }
        })
        this.loaderFlag = false
      } else {
        alert('Verification code is incorrect')
        this.loaderFlag = false
      }
    })

  }
  ressendOtp(): void {
    this.CounterDirective.first.startTimer();
    this.onValueChange.emit(-2);
    this.loaderFlag = true
    this.postData.type = 'forgetPassword'
    this.postData.email = sessionStorage.getItem('otpData')
    this.OfflineBookingService.sendVarificationCode(this.postData).subscribe((otpRes) => {
      if (otpRes.message == "Email you have entered is validated." && otpRes.statusCode == 200) {
        alert('OTP has been sent to your email Id')
        this.loaderFlag = false
      } else {
        alert('Email you have entered not register with us')
        this.loaderFlag = false
      }

    }, (err) => {
      alert(err)
      this.loaderFlag = false
    })
  }

  public formatSecsToMins(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
}
