import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserType } from '../models';
import { SharedService } from 'app/services/shared.service';
import { MatDialogServiceService } from 'app/offline-booking/Offline-booking-Services/mat-dialog-service.service';
import { OfflineBookingService } from 'app/offline-booking/Offline-booking-Services/offline-booking.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loaderFlag: boolean;
  currentUser: UserType = new UserType();
  username: string;
  password = '';
  errorMsg: string;
  returnUrl: string;
  error: any;
  isSubmit=false
  isActiveError: boolean;
  isActiveErrorValue: any;
  submitted: boolean;
  displayTitle: any;
  binPopupFlag: any ='resetpassword';
  constructor(private _userServ: UserService, private route: ActivatedRoute, private router: Router,private appService:SharedService,private dialogService: MatDialogServiceService,private _OfflineBookingService:OfflineBookingService) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this._userServ.getLogin(this.username, this.password).subscribe(resdata => {
      this.loaderFlag = true;
      this.isActiveError =false;
      if (resdata?.user) {
        sessionStorage.setItem('token', resdata.user?.token)
        if (resdata?.user?.profilerKey) {
          sessionStorage.setItem('profilerToken', resdata.user.profilerKey)
          this._OfflineBookingService.customerProfilerLogin({ "agentAuthenticationKey": resdata.user.profilerKey }).subscribe(loginProfileResponse => {
            sessionStorage.setItem('loginProfileToken', loginProfileResponse.agentAuthorizationToken)
          }, err => {
            this.loaderFlag = false
          })
        } else {
          this.loaderFlag = false
          // alert('Profile Key is Not Available For This User')
        }
        localStorage.setItem('userID', resdata.userID)
        localStorage.setItem('teamLeadID',resdata.user.email)
        let postData = {
          email: resdata.user.email
        }
        let headers = {
          "Authorization": "Token " + resdata.user.token,
          "Content-Type": "application/json"
        }
        this._userServ.getUser(postData, headers).subscribe(data => {
          console.log(data,"line 48")
          localStorage.setItem('UserName', data.data.username);
          localStorage.setItem('UserType', data.data.role);
          localStorage.setItem('token',resdata.user.token)
          localStorage.setItem('permissionList', JSON.stringify(data.data.permissionList));
          localStorage.setItem('roleList', JSON.stringify(data.data.roles));

          this.appService.setUserLoggedIn(true)
          if (data.role == 'TeamLead') {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['dashboard']);
          }
        })
      }
      else if(resdata.status ==200){
        this.loaderFlag =false;
        if(resdata.message =="Your account is not active.Please contact admin.")
        {
          this.isSubmit=false;
          this.isActiveError =true;
          this.isActiveErrorValue = resdata.message;
        }
      }else if(resdata.status ==201){
         this.loaderFlag= false;
          this.isSubmit=false;
          this.isActiveError =true;
          this.isActiveErrorValue = resdata.message;
      }
    }, err => {
      this.isActiveError =false;
      this.error = "Username or Password is Incorrect"
      this.loaderFlag = false;
      this.isSubmit=false;
    });
    this.isSubmit=true

  }
  resetPassword(){
    this.openDialogBox()
  }
  openDialogBox(){
    this.submitted = true;
       this.dialogService.OpenConfirmDialog(this.displayTitle,this.binPopupFlag).afterClosed().subscribe(res => { })
    }


  activateFP() {
    this.router.navigate(['forgot-password']);
  }

}

