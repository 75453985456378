import { Component, Input, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.css']
})
export class MatConfirmDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  flag :boolean
  binPopupFlag ='';
  searchPopup: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data,  private router: Router,
  public dialogRef: MatDialogRef<MatConfirmDialogComponent>) { }

  ngOnInit(): void {
   this.binPopupFlag = this.data.flag
  }
  dismiss(){
  this.dialogRef.close(false);
}
newRegistration(){
  this.router.navigate(['new-user-registration'])
}
newQuery(){
  this.router.navigate([''])
}
}
