<!DOCTYPE html>
<html>
      <head>
            <!-- Required meta tags -->
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
            <link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet">
             <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.3.0/css/datepicker.css" rel="stylesheet" type="text/css" />
            <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet">
            <title>Europ Healthcare</title>
      </head>
      <body class="login-page">
            <div class="se-pre-con" *ngIf="loaderFlag" id="loader"></div>
            <div class="login-main">
                  <div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
                        <div class="logos ">
                            <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                            <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                        </div>
                  </div>
                  <div class="left-area clearfix">
                        <div class="logos hidden-sm hidden-xs">
                              <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                              <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                              
                        </div>
                        
                        <div class=" col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <figure class="login-img">
                                    <img src="../../../assets/img/family.svg" class="img-responsive" alt="login-image">
                              </figure>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-login">
                                    <div class="login-title"><h3 class="text-center">Forgot password?</h3></div>                              
                                    <form class="forgotpassword-email" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm">
                                          <p class="text-center">Provide us your registered email address and we will send
                                          you an email with instructions to reset your password.</p>
                                          <div class="form-group">
                                                <label for="username">Enter your registered email address</label>
                                                <input type="text" placeholder="Enter email address" name="email" class="form-control no-autofill-bkg" [(ngModel)]="postData.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
                      <div *ngIf="f.submitted && email.invalid" class="alert alert-danger">
                          <div *ngIf="email.errors.required">Email is required</div>
                          <div *ngIf="email.errors.email">Email must be a valid email address</div>
                      </div>
                                          </div>
                                          <button type="submit"  class="btn btn-primary submit-btn">Submit</button>
                                    </form>
                                  
                              </div>
      
                        
      
                              <div class="form-login" style="display: none;">
                                    <div class="login-title"><h3 class="text-center">Forgot password?</h3></div>                              
                                    <form class="forgotpassword-email" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm">
                                          <p class="pas-otp-het"></p>
                                          <div class="form-group post-reltv">
                                                <label for="username" class="m-b-10">Enter validation code sent to your email address</label>
                                                <div class="post-time">8:20</div>
                                                <input type="text" placeholder="Enter here" name="email" class="form-control no-autofill-bkg" [(ngModel)]="postData.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
                      <div *ngIf="f.submitted && email.invalid" class="alert alert-danger">
                          <div *ngIf="email.errors.required">Email is required</div>
                          <div *ngIf="email.errors.email">Email must be a valid email address</div>
                      </div>
                                          </div>
                                          <button type="submit"  class="btn btn-primary submit-btn">Submit</button>
                                          <button type="submit"  class="btn btn-primary resend-btn m-t-30">Resend</button>
                                    </form>
                                    <div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
                                          <div class="logos ">
                                              <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                                              <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                                          </div>
                                    </div>
                              </div>
      
      
                              <div class="form-login" style="display: none;">
                                    <div class="login-title"><h3 class="text-center">Forgot password?</h3></div>                              
                                    <form class="forgotpassword-email" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm">
                                          <p class="pas-otp-het"></p>
                                          <div class="form-group post-reltv">
                                                <label for="username" class="m-b-10">Enter validation code sent to your email address</label>
                                             
                                                <input type="text" placeholder="Enter here" name="email" class="form-control no-autofill-bkg" [(ngModel)]="postData.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
                      <div *ngIf="f.submitted && email.invalid" class="alert alert-danger">
                          <div *ngIf="email.errors.required">Email is required</div>
                          <div *ngIf="email.errors.email">Email must be a valid email address</div>
                      </div>
                                          </div>
                                          <button type="submit"  class="btn btn-primary submit-btn">Submit</button>
                                          <button type="submit"  class="btn btn-primary resend-btn m-t-30">Resend</button>
                                    </form>
                                    <div class="logo-mobile-only hidden-lg hidden-md visible-sm visible-xs">
                                          <div class="logos ">
                                              <div class="logo-1"><a href="#"><img src="../../../assets/img/home-care.svg" alt="home-care-logo" class="img-responsive"></a></div>
                                              <div class="logo-2"><a href="#"><img src="../../../assets/img/logo.svg" alt="europ-logo" class="img-responsive"></a></div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  
                  <!-- forgot password -->
            </div>
      </body>
</html>